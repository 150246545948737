@font-face {
  font-family: "ALS Sirius";
  src: url("/src/Shrifts/ALS_Sirius_Regular_0.95.otf") format("opentype");
}

@font-face {
  font-family: "Inter";
  src: url("/src/Shrifts/ALS_Sirius_Bold_0.95.otf") format("opentype");
}

@font-face {
  font-family: "ALSTPU";
  src: url("/src/Shrifts/ALSTPU.otf") format("opentype");
}

.gigaSVG{
  width: min( 100px, calc(50px + 10 * ((100vw - 320px) / 1024)));
}

.mediumSmallText{
  color: #000;
  font-family: ALS Sirius;
  font-size: min( 24px, calc(16px + 8 * ((100vw - 320px) / 1024)));
  font-weight: 700;
}

.mediumSmallSVG{
  width: min( 22px, calc(14px + 8 * ((100vw - 320px) / 1024)));
}

.bigText{
  color: #28BE46;
  font-family: Inter;
  font-size: min( 40px, calc(25px + 8 * ((100vw - 320px) / 1024)));
  font-weight: 700;
}

.bigSVG{
  width: min( 50px, calc(25px + 10 * ((100vw - 320px) / 1024)));
}

.mediumText{
  color: #000000;
  font-family: Inter;
  font-size: min( 30px, calc(18px + 8 * ((100vw - 320px) / 1024)));
  font-weight: 700;
}

.mediumSVG{
  width: min( 24px, calc(14px + 8 * ((100vw - 320px) / 1024)));
}

.smallText{
  color: #000;
  font-family: ALS Sirius;
  font-size: min( 20px, calc(14px + 8 * ((100vw - 320px) / 1024)));
  font-weight: 700;
}

.smallSVG{
  width: min( 20px, calc(14px + 8 * ((100vw - 320px) / 1024)));
}

.tinyText{
  color: #000;
  font-family: ALS Sirius;
  font-size: min( 16px, calc(10px + 8 * ((100vw - 320px) / 1024)));
  font-weight: 300;
}

.tinySVG{
  width: min( 16px, calc(10px + 8 * ((100vw - 320px) / 1024)));
}


.ultraTinyText{
  color: #000;
  font-family: ALS Sirius;
  font-size: min( 14px, calc(8px + 8 * ((100vw - 320px) / 1024)));
  font-weight: 300;
}

.tinySVG{
  width: min( 16px, calc(10px + 8 * ((100vw - 320px) / 1024)));
}