.box{
    /* border: 1px solid #000; */
    width: 100%;
    border: 1px solid #000;
}

.points_but{
    display: flex;
    text-align: center;
    justify-content: center;
}

.catalog_page_counter{
    align-items: center;
    height: 60px;
    margin: 0 auto;
    /* width: max-content;
    min-width: 6px; */
    display: flex;
    justify-content:space-evenly;
    gap: 5%;
}

.button_footer{
    display: flex;
    cursor: pointer;
    background-color: rgb(219, 219, 219);
    /* height: 50px;
    width: 50px; */
    width: 100%;
    border-radius: 10px;
    composes: mediumSmallText from './../Global_Styles/fontsStyles.module.css';
    border: 0;
}

.SELECTED_BUT_FOOTER{
    background-color: rgb(40, 190, 70);
}

@media (max-width: 650px) {

}
  
@media (max-width: 380px) {

}