.react-calendar {

    width:29%;
    min-width: 420px;
    /* height:400px; */
    margin-left: auto;
    margin-right: auto;

    border-radius: 16px;
    background: rgb(255, 255, 255);
    
    font-family: inter;
    line-height: 1.125em;
    margin-top: 10px;
    padding: 2%;
    
}

.react-calendar--doubleView {
    width: 700px;
    
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
    
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
    width: 50%;
    margin: 0.5em;
    color:red;
    background-color:red;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    
}

.react-calendar button {
    
    border: 3px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    
    color: rgba(0, 0, 0, 0.2);
    margin:5px;
    margin-right:5px;
    font-size: 26px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0%;
    margin-left:auto;
    
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    display: flex;
    height: 50px;
    margin-bottom: 1em;
    border-radius: 8px;
    background: rgba(29, 25, 25, 0.1);
    
}

.react-calendar__navigation button {
    min-width: 34px;
    background: none;
    border:none;
    color: #000000;
}

.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-family: inter;
    font-size: 1em;
    
    
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font: inherit;
    font-size: 0.75em;
    font-weight: bold;
    
}

.react-calendar__month-view__days__day--weekend {
    color: #d10000;
    
}
.react-calendar__year-view {
    display: none;
  }
  

.react-calendar__month-view__days__day--neighboringMonth,
.react-calendar__decade-view__years__year--neighboringDecade,
.react-calendar__century-view__decades__decade--neighboringCentury {
    color: #cf2727;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
    
}

.react-calendar__tile {
    padding:7px;
    
    text-align: center;
    line-height: 16px;
    font: inherit;
    font-size: 0.833em;
    

}

.react-calendar__tile:disabled {
    background-color: #f0f0f0;
    color: #e10f0f;
}

.react-calendar__month-view__days__day--neighboringMonth:disabled,
.react-calendar__decade-view__years__year--neighboringDecade:disabled,
.react-calendar__century-view__decades__decade--neighboringCentury:disabled {
    color: #cdcdcd;
    
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}

/* .react-calendar__tile--now {
    background: #ffffff;
} */

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #63d96d;
}

.react-calendar__tile--hasActive {
    background: #63d96d;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #5ac663;
}

.react-calendar__tile--active {
    background: rgb(192, 255, 205);
    border: 3px solid rgb(34, 159, 59);
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: rgb(192, 255, 205);
    border: 3px solid rgb(34, 159, 59);
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}

.react-calendar__navigation_arrow{
    background-color: #63d96d;
}

@media (max-width: 990px) {
    .react-calendar {
        width:60%;
    }
}

@media (max-width: 680px) { 
    .react-calendar {
        width:65%;
        min-width:360px;
    }
}

@media (max-width: 380px) {
    .react-calendar {
        width:360px;
    }
}