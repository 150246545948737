 
/* @import './../../Global_Styles/fonts.css'; */

.rootStyle {
  width: 80%;
  margin: 0 auto;
  margin-top: 2%;
  max-width: 1000px;
  border: 1px solid #000;
}

.iterFontStyle {
  font-family: "Inter";
  color:var(--color-red);
  composes: bigText from './../../../Global_Styles/fontsStyles.module.css';
}


.headApplications{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5%;
}

.titleBlock {
  display: flex;
  flex-direction: column;
  width: auto;
}

.titleLabel {
  color:var(--light-green);
}
.subLabel {
  display: inline-flex;
  align-items: center;
  align-items: center;
  composes: mediumSmallText from './../../../Global_Styles/fontsStyles.module.css';
}

.newApplicationsSVG {
  composes: mediumSmallSVG from './../../../Global_Styles/fontsStyles.module.css';
  height: 80%;
  margin-right: 2%;
}

.subTopBlock {
  display: flex;
  flex-direction: column;
  align-items:center ;
  width: 40%;
  max-width: 231px;
  min-width: max-content;
}

.sortMenu {
  display: flex;
  align-items: center;
  width: 100%;
  height: 50%;
}

.rejectButton {
  display: flex;
  width: max-content;
  align-items: center;
  height: 50%;
  width: 100%;
}

.newApplicationsBlock{
  border-bottom: 2px solid var(--light-gray);
}
