.wrap{
  display: none;
}

@media (max-width: 650px) {
  .wrap{
    display: block;
    transform: none;
  }

  .navBody{
    z-index: 100000;
    background-color: var(--light-gray);
    width: 100vw;
    height: 52px;
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    justify-content: space-around;
  }
  
  .navBody > ul{
    padding: 0;
    margin: 0;
    display: flex;
  }
  
  .navbar_el{
    display: flex;

    height: 100%;
    width: 100%;
    justify-content: center;
  }

  .navbar_el:active{
    background-color: var(--dark-gray);
  }
  
  .icon{
    width: 60%;
  }
  
  .navbar_label{
    display: none;
  }
}

@media (max-width: 380px) {
  .navBody{
    width: 380px;
  }
}