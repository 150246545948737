
.butBody{
  width: 100%;
  height: 80%;
  min-height: max-content;
  padding: 0 5% 1% 5%;
  /* background-color: rgb(196,32,42); */
  background-color: var(--color-red);
  color: var(--color-white);
  composes: smallText from './../../../Global_Styles/fontsStyles.module.css';
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  text-align: center;
}

.butBody > label {
  width: 100%;
}

.butBody:hover{
  background-color: var(--dark-red);
}