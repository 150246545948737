.main_flexBox{
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: auto;
}

.top_cont_forUIpage{
    position: relative;
}

.body_cont_forUIpage{
    position: relative;
    display: flex;
    flex-direction: row;
    height: auto;
    min-height: 200vh;
}

@media (max-width: 650px) {
    
}