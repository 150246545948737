.mainBody{
  width: auto;
  height: auto;
}

.popupBack {
  display: block;
  position: fixed;
  z-index: 1020;
  top: 0;
  left: 0;
  width: 200vw;
  height: 200vh;
  background-color: var(--color-black);
  opacity: 0.4;
  /* backdrop-filter: blur(40px); */
  cursor:default;
}

.popupBody{
  display: block;
  z-index: 1021;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: max-content;
  min-width: min-content;
  height: max-content;
  opacity: 1;
  margin: auto;
  background-color: var(--color-white);
  transition: 400ms;
  padding: 2%;
  border-radius: 16px;
  cursor:default;
}

.dspNone{
  z-index: 1022;
  display: hidden;
  position: absolute;
  margin: auto;
  top: -1000px;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  height: 0;
  width: 0;
  transition: 100ms;
}


@media (max-width: 380px) {
  .popupBack {

    width: 380px;
    height: 1200vh;

  }

  .popupBody{
    /* top: 200px;
    left: 5%; */
    top: 30px;
    max-width: 340px;
    width: max-content;
    
    background-color: var(--color-white);
  }

}

