.profil_myproject_curproject_conteiner{
    margin-top: 22px;
}

.profil_myproject_curproject{
    width: 100%;
    padding: 1% 2%;
    border-radius: 8px;
    background: rgba(34, 159, 59, 0.15);
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
}

.profil_myproject_curproject_leftPart{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 58%;
    padding-left: 1.5%;

}

.profil_myproject_curproject_info{
    width: 100%;

    height: 48px;
    color: rgba(34, 159, 59, 0.8);
    
    font-family: Inter;
    font-size: min( 32px, calc(22px + 8 * ((100vw - 320px) / 1024)));
    font-weight: 700;
    margin-top: 5%;

}
.profil_myproject_curproject_ist{
    
    margin-top: -5%;
    width: 80%;


    color: #000000;
    opacity: 0.9;
    font-family: ALS Sirius;
    font-size: min( 16px, calc(12px + 8 * ((100vw - 320px) / 1024)));
    font-weight: 400;
}
.profil_myproject_curproject_hesh{
    width: 40%;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 4%;
    padding-right: 4%;
    border-radius: 7px;
    background: rgba(0, 0, 0, 0.22);
    display: inline-flex;
    color: #FFF;

    font-family: ALS Sirius;
    font-size: min( 24px, calc(16px + 8 * ((100vw - 320px) / 1024)));
    font-weight: 700;
    opacity: 0.7;
    text-align: center;
    justify-content: center;
    align-items: center;

}


.profil_myproject_curproject_conthesh{
    height: auto;
    width: 80%;

    display: flex;
    justify-content: space-around;
}


.profil_myproject_curproject_right{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 38%;
}
.profil_myproject_curproject_righ_date{
    width: 80%;
    text-align: left;

    color: rgba(0, 0, 0, 0.5);
    
    font-family: Inter;
    font-size: min( 16px, calc(12px + 6 * ((100vw - 320px) / 1024)));
    font-weight: 700;

}

.profil_myproject_curproject_righ_cont{

    width: 80%;
    display: flex;
    align-items: center;
    gap: 16px;


}
.profil_myproject_curproject_righ_img{
    display: flex;
    width: 40px;
    height: 40px;
    padding: 1.5px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    opacity: 0.4;
    background: #000;
    padding: 2px;
}

.profil_myproject_curproject_righ_img svg{
    width: 75%;
}

    
.profil_myproject_curproject_righ_boxText{

    color: rgba(0, 0, 0, 0.50);
    
    text-align: center;
    font-family: ALS Sirius;
    font-size: min( 16px, calc(12px + 6 * ((100vw - 320px) / 1024)));
    font-weight: 700;

}
.mobile_ver_cp{display: none;}

.profil_myproject_curproject_right:last-child svg {
    width: 90%;
}

@media (max-width: 821px) {
    .profil_myproject_curproject_righ_img{
        width: 35px;
        height: 35px;
        min-width: min-content;

    }
}

@media (max-width: 780px) {
    .profil_myproject_curproject_righ_img{
        width: 30px;
        height: 30px;
        min-width: min-content;

    }
    .profil_myproject_curproject_right:last-child svg {
        width: 25px;
    }
}

@media (max-width: 725px) {
    .profil_myproject_curproject_righ_img{
        width: 25px;
        height: 25px;
        min-width: min-content;

    }
}

@media (max-width: 650px) {
    .profil_myproject_curproject_righ_img{
        width: 20px;
        height: 20px;
        min-width: min-content;

    }
}


@media (max-width: 550px) {
    .profil_myproject_curproject{
        height: auto;
    }
    .profil_myproject_curproject_info{
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
    }
    .profil_myproject_curproject_ist{
        width: 80%;
    }
    .profil_myproject_curproject_conthesh{
        width: 100%;
        height: 24px;
        margin-top: -8%;
        margin-left: -5%;
        padding-bottom: 5%;
    }
    .profil_myproject_curproject_hesh{
        margin: 0;
        height: 100%;
        padding: 0 2.5%;
        margin: 0;
        font-size: min( 16px, calc(12px + 4 * ((100vw - 320px) / 1024)));
        text-align: center;
        align-items: center;
        min-width: max-content;
    }
    .profil_myproject_curproject_righ_date{
        font-size: min( 12px, calc(10px + 2 * ((100vw - 320px) / 1024)));
        text-align: center;
        margin-left: -20%;
        width: 100%;
        height: 20px;
        line-height: 20px;
    }
    .profil_myproject_curproject_righ_img{
        width: 18px;
        height: 18px;
        margin-right: -7%;
    }
    .profil_myproject_curproject_right:last-child svg {
        width: 18px;
    }
    .desktop_ver_cp{display: none;}
    .mobile_ver_cp{
        display: flex;
    }
    .profil_myproject_curproject_righ_boxText{
        font-size: min( 12px, calc(10px + 4 * ((100vw - 320px) / 1024)));
        padding-left: 1%;
        width: 70%;
        margin-left: -10px;
    }
    .profil_myproject_curproject_righ_cont{
        width: 100%;
    }
}

